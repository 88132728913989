/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import CampusLayout from './campusLayout';
import VideoContent from '../../components/universities/video';
import { getDevice } from '../../utils/util';
import { throttle } from '../../utils/helper';

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const copywriting = data.contentfulCopywriting;
    const { banner } = copywriting.campusRecruitment;

    banner.map((image) => { image.img = image[getDevice()]; });
    this.setState({ banner });
    // 切换设备
    window.addEventListener('resize', throttle(() => {
      banner.map((image) => { image.img = image[getDevice()]; });
      this.setState({ banner });
    }, 500));
  }

  render() {
    const { location, data } = this.props;
    const copywriting = data.contentfulCopywriting;
    const { videos } = copywriting.campusRecruitment;
    return <CampusLayout
      copywriting={copywriting}
      location={location}>
      {videos && <VideoContent temp={videos}></VideoContent>}
    </CampusLayout>;
  }
}

export default Videos;

export const query = graphql`
query videoQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    campusRecruitment {
      seo {
        description
        keywords
        title
      }
      banner {
        mobile
        pc
        jump_link
      }
      sidebar {
        text
        url
      }
      videos {
        title
        video
        content
        poster
      }
    }
  }
} 
`;
